import {
  createRouter,
  createWebHistory
} from 'vue-router'

const Home = () => import( /* webpackChunkName: "home" */ '../views/Home.vue');
const Dashboard = () => import( /* webpackChunkName: "dash" */ '../views/dashboard.vue');
const Login = () => import( /* webpackChunkName: "login" */ '../views/login.vue');
const Store = () => import( /* webpackChunkName: "store" */ '../views/store.vue');

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Dashboard,

  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
  },
  {
    path: '/:id',
    name: 'Store',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Store
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router